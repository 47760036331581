import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")
  const _component_Loader = _resolveComponent("Loader")
  const _component_OfflineView = _resolveComponent("OfflineView")
  const _component_ErrorModal = _resolveComponent("ErrorModal")

  return (_openBlock(), _createBlock("div", {
    class: ["app_wrapper", {
      '-fix': !_ctx.$store.state.isScroll,
      '-os-android': _ctx.isAndroid,
    }]
  }, [
    _createVNode(_component_router_view, {
      isShowAuth: _ctx.isShowAuth,
      reauthKey: _ctx.reauthKey,
      successAuthorize: _ctx.successAuthorize
    }, null, 8, ["isShowAuth", "reauthKey", "successAuthorize"]),
    (_ctx.$store.state.isLoading)
      ? (_openBlock(), _createBlock(_component_Loader, { key: 0 }))
      : _createCommentVNode("", true),
    (_ctx.$store.state.isOffline)
      ? (_openBlock(), _createBlock(_component_OfflineView, { key: 1 }))
      : _createCommentVNode("", true),
    (_ctx.$store.state.errorModal.show)
      ? (_openBlock(), _createBlock(_component_ErrorModal, { key: 2 }))
      : _createCommentVNode("", true)
  ], 2))
}